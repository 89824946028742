<script lang="ts">
  export let variant: "primary" | "secondary" | "yellow" | "blue" = "secondary";

  export let href: string = "";
  export let newTab = false;
  export let external = false;
  export let disabled = false;
  export let selected = false;
  export let type: "button" | "submit" = "button";
</script>

{#if href}
  <a
    {href}
    on:click
    target={newTab ? "_blank" : undefined}
    rel={newTab ? "noreferrer" : external ? "external" : ""}
    class="btn-marketing"
    class:btn-primary={variant === "primary"}
    class:btn-yellow={variant === "yellow"}
    class:btn-blue={variant === "blue"}
    class:btn-secondary={variant === "secondary"}
    class:disabled
    class:selected
    tabIndex={disabled ? -1 : 0}
  >
    <slot />
  </a>
{:else}
  <button
    on:click
    class="btn-marketing"
    class:btn-primary={variant === "primary"}
    class:btn-yellow={variant === "yellow"}
    class:btn-blue={variant === "blue"}
    class:btn-secondary={variant === "secondary"}
    class:disabled
    class:selected
    {disabled}
    {type}
  >
    <slot />
  </button>
{/if}

<style lang="postcss">
  .btn-marketing {
    @apply rounded-full px-5 py-2 inline-flex justify-center items-center whitespace-nowrap;
    @apply text-sm font-normal transition-all ease-out focus:outline-none;
    @apply focus-visible:ring ring-brand-green/50;
  }

  .btn-marketing.disabled {
    @apply opacity-50 cursor-not-allowed;
  }

  .btn-marketing:not(.disabled) {
    @apply active:scale-[97%];
  }

  .btn-marketing.btn-primary {
    @apply px-[21px] py-[9px]; /* 1px extra padding to make up for the border */
    @apply text-black font-medium bg-brand-green;

    &:not(.disabled):hover,
    &.selected {
      @apply bg-light-green;
    }
  }

  .btn-marketing.btn-secondary {
    @apply border border-light-green/30;
    @apply text-light-green;

    &:not(.disabled):hover,
    &.selected {
      @apply text-brand-green bg-light-green/5 border-brand-green;
    }
  }

  .btn-marketing.btn-yellow {
    @apply px-[21px] py-[9px]; /* 1px extra padding to make up for the border */
    @apply text-black font-medium bg-muted-yellow ring-muted-yellow/50;

    &:not(.disabled):hover {
      @apply bg-muted-yellow/60;
    }
  }

  .btn-marketing.btn-blue {
    @apply px-[21px] py-[9px]; /* 1px extra padding to make up for the border */
    @apply text-black font-medium bg-bright-blue ring-bright-blue/50;

    &:not(.disabled):hover {
      @apply bg-bright-blue/60;
    }
  }
</style>
